.iphone-case {
    // background: url('./../../../assets/images/PHONES_SPRITE_IMAGE.png') no-repeat 0 0;
    background: url('./../../../assets/images/iphone-blank-case.png') no-repeat 0 0;
    //background-size: 420px 2400px;
    // background-size: 338px 688px;
    overflow: scroll;
    text-indent: -500px;
    border: 0;
    display: inline-block;
    // width: 350px;
    height: 550px;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    background-position: center 0;

    @media screen and (min-width: 250px) {
        width: 250px;
        background-size: auto;
        top: 42px;
        // display: none;
    }
    @media screen and (min-width: 600px) {
        width: 350px;
        background-size: 338px 688px;
        top: 60px;
        height: 758px;
        // display: block;
    }
}

.carousel-content {
    @media screen and (min-width: 250px) {
        height: 450px;
    }
    @media screen and (min-width: 600px) {
        height: 550px;
    }
}
