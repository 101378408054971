// Colors

// The color palette
$palette: (
    light: (
        1: #ffffff,
        2: #f9fafc,
        3: #e9ecf8,
    ),
    dark: (
        1: #2e3458,
        2: #575e86,
        3: #9298b8,
    ),
    primary: (
        1: #0ebe7d,
        2: #55ca94,
        3: #00b267,
        4: #bae8d0,
    ),
    secondary: (
        1: #be0e4f,
        2: #d31253,
        3: #aa074b,
        4: #f8b9cb,
    ),
    alert: (
        error: #ff6f66,
        warning: #ffcd6e,
        success: #00c2a9,
    ),
);

// Base typography: color
$color: (
    heading: get-color(dark, 1),
    base: get-color(dark, 2),
    high-contrast: get-color(dark, 1),
    mid-contrast: get-color(dark, 2),
    low-contrast: get-color(dark, 3),
    primary: get-color(primary, 1),
    secondary: get-color(secondary, 1),
    error: get-color(alert, error),
    warning: get-color(alert, warning),
    success: get-color(alert, success),
    // ↓ Inverted colors
    heading-inverse: get-color(light, 1),
    base-inverse: get-color(dark, 3),
    high-contrast-inverse: get-color(light, 1),
    mid-contrast-inverse: get-color(light, 2),
    low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
    divider: get-color(light, 3),
    divider-inverse: rgba(get-color(dark, 2), 0.24),
);

// Icons: fill color
$icon--color: (
    hamburger: get-color(dark, 2),
    // hamburger icon
    accordion: get-color(primary, 1),
    // accordion icon
    social: get-color(primary, 1),
    // social icons
    social-hover: get-color(primary, 2),
    // social icons (:hover)
    modal: get-color(dark, 3),
    // modal icon
    modal-hover: get-color(dark, 2),
    // modal icon (:hover)
    news-more: get-color(primary, 2),
    // news - read more link arrow
    // ↓ Inverted colors
    hamburger-inverse: get-color(light, 2),
    // hamburger icon
    accordion-inverse: get-color(light, 2),
    // accordion icon
    social-inverse: null,
    // social icons
    social-hover-inverse: null // social icons (:hover),,,,,,,,,
);

// Misc elements: background color
$bg--color: (
    body: get-color(light, 1),
    body-shadow: 0 20px 48px rgba(get-color(dark, 1), 0.1),
    // body shadow when a boxed layout is used (set to null if don't want a shadow)
    body-outer: get-color(light, 2),
    // outer bg color when a boxed layout is used
    bg-color: get-color(dark, 1),
    // .has-bg-color helper class
    shadow: 0 12px 32px rgba(get-color(dark, 1), 0.24),
    // .has-shadow helper class
    code: get-color(light, 2),
    code-inverse: darken(get-color(dark, 1), 3%),
);
