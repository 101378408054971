// Font Family
@mixin font-family($elem) {
    font-family: unquote(get-font-family($elem));
}

// Font-size + Line-height + Kerning
@mixin font-size($elem) {
    font-size: get-font-size($elem);
    line-height: get-line-height($elem);
    letter-spacing: get-kerning($elem);
}

// Font Weight
@mixin font-weight($elem) {
    font-weight: get-font-weight($elem);
}

// Anchor aspect
@mixin anchor-aspect($type: 'main', $layout: false) {
    @if ($type == 'main') {
        // Base
        @if ($layout == 'inverse') {
            color: color(link-inverse);
        } @else {
            color: color(link);
        }
        text-decoration: $link-main--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(link-hover-inverse);
            } @else {
                color: color(link-hover);
            }
            outline: 0;
            text-decoration: $link-main--decoration-hover;
        }
    } @else if ($type == 'header') {
        @if ($layout == 'inverse') {
            color: color(header-link-inverse);
        } @else {
            color: color(header-link);
        }
        text-decoration: $link-header--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(header-link-hover-inverse);
            } @else {
                /* color: color(header-link-hover); */
            }
            text-decoration: $link-header--decoration-hover;
        }
    } @else if ($type == 'header-mobile') {
        color: color(header-link-mobile);

        &:hover,
        &:active {
            color: color(header-link-mobile-hover);
        }
    } @else if ($type == 'footer') {
        @if ($layout == 'inverse') {
            color: color(footer-link-inverse);
        } @else {
            color: color(footer-link);
        }
        text-decoration: $link-footer--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(footer-link-hover-inverse);
            } @else {
                color: color(footer-link-hover);
            }
            text-decoration: $link-footer--decoration-hover;
        }
    } @else if ($type == 'footer-block') {
        @if ($layout == 'inverse') {
            color: color(footer-block-link-inverse);
        } @else {
            color: color(footer-block-link);
        }
        text-decoration: $link-footer-block--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(footer-block-link-hover-inverse);
            } @else {
                color: color(footer-block-link-hover);
            }
            text-decoration: $link-footer-block--decoration-hover;
        }
    } @else if ($type == 'button-link') {
        @if ($layout == 'inverse') {
            color: color(button-link-inverse);
        } @else {
            color: color(button-link);
        }
        text-decoration: $link-button--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(button-link-hover-inverse);
            } @else {
                color: color(button-link-hover);
            }
            text-decoration: $link-button--decoration-hover;
        }
    } @else if ($type == 'func-link') {
        @if ($layout == 'inverse') {
            color: color(func-link-inverse);
        } @else {
            color: color(func-link);
        }
        text-decoration: $link-func--decoration;

        &:hover,
        &:active {
            @if ($layout == 'inverse') {
                color: color(func-link-hover-inverse);
            } @else {
                color: color(func-link-hover);
            }
            text-decoration: $link-func--decoration-hover;
        }
    }
}

@mixin divider-mix($layout: false) {
    display: block;
    height: 1px;
    @if ($layout == 'inverse') {
        background: color-border(divider-inverse);
    } @else {
        background: color-border(divider);
    }
}

@mixin divider($type: false, $layout: false) {
    @if ($type == 'before') {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include divider-mix($layout);
        }
    } @else if ($type == 'after') {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            @include divider-mix($layout);
        }
    } @else {
        @include divider-mix($layout);
    }
}

// Handles a scalabale background image related to a specific elements
// $file: name of file (e.g. illustration-element-01.svg)
// $width: (in %) illustration width as a percent of related element width
// $height: (in % or px) illustration height as a percent of related element width (scaling) or illustration height in px
// $vpos: illustration is centered by default, allowed values: top or bottom
// $hoffset: horizontal offset
// $voffset: vertical offset
@mixin illustration($file, $width, $height, $vpos: null, $hoffset: null, $voffset: null) {
    content: '';
    position: absolute;
    width: $width;
    max-width: $width;
    height: $height;
    background-image: url(../../../images/#{$file});
    background-repeat: no-repeat;
    @if (unit($height) == '%') {
        background-size: contain;
    } @else {
        background-size: auto;
    }
    @if ($hoffset) {
        left: calc(50% + #{$hoffset});
    } @else {
        left: 50%;
    }
    @if ($vpos == 'top') {
        @if ($voffset) {
            top: #{$voffset};
        } @else {
            top: 0;
        }
        background-position: center top;
        transform: translate(-50%);
    } @else if ($vpos == 'bottom') {
        @if ($voffset) {
            bottom: #{$voffset};
        } @else {
            bottom: 0;
        }
        background-position: center bottom;
        transform: translate(-50%);
    } @else {
        background-position: center;
        @if ($voffset) {
            top: calc(50% + #{$voffset});
        } @else {
            top: 50%;
        }
        transform: translate(-50%, -50%);
    }
}
